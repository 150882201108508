<template>
  <div class="home">
    <div class="logo">
      <img alt="Vue logo" src="../assets/new_logo.webp">
    </div>
    <h1>Welcome to GoFinGraph</h1>
    <p>Manage your finances with ease</p>
    <p>Click below to login or register</p>
    <div>
      <router-link to="/login">Login</router-link> |
      <router-link to="/register">Register</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    login() {
      this.$router.push('/login')
    },
    register() {
      this.$router.push('/register')
    }
  }
}
</script>

<style>
.logo img{
  text-align: center;
  height: 300px;
  width: 300px;
  margin-top: 20px;
}
</style>
